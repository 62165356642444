/* eslint-disable jsx-a11y/media-has-caption */
import { VideoProviderEnum } from '@utmb/types/enum/VideoProviderEnum';
import { VideoProviderUrlEnum } from '@utmb/types/enum/VideoProviderUrlEnum';
import SliceVideoOnlyView from '@utmb/types/view/SliceVideoOnlyView';
import React, { useEffect, useRef, useState } from 'react';
import Script from 'next/script';

import styles from './video.module.scss';
import ConsentAware from '@utmb/design-system/consent/ConsentAware';
import { CookieCategorieEnum } from '@utmb/design-system/types/cookie-consent-helper';

export const Video = (props: SliceVideoOnlyView) => {
    const { provider, providerCode } = props;

    const videoContainer = useRef<HTMLDivElement>(null);

    const [videoHeight, setVideoHeight] = useState<number>(0);

    useEffect(() => {
        if (videoContainer.current?.offsetWidth) {
            setVideoHeight(videoContainer.current.offsetWidth / (16 / 9));
        }
    }, [videoContainer.current?.offsetWidth]);

    const videoUrl = {
        [VideoProviderEnum.DAILYMOTION]: `${VideoProviderUrlEnum.DAILYMOTION}${providerCode}`,
        [VideoProviderEnum.FACEBOOK]: `${VideoProviderUrlEnum.FACEBOOK}${providerCode}`,
        [VideoProviderEnum.VIMEO]: `${VideoProviderUrlEnum.VIMEO}${providerCode}`,
        [VideoProviderEnum.YOUTUBE]: `${VideoProviderUrlEnum.YOUTUBE}${providerCode}`,
    };

    const consents: CookieCategorieEnum[] = [];
    switch (provider) {
        case VideoProviderEnum.DAILYMOTION:
            consents.push(CookieCategorieEnum.FUNCTIONNAL);
            break;
        case VideoProviderEnum.FACEBOOK:
            consents.push(CookieCategorieEnum.FUNCTIONNAL);
            consents.push(CookieCategorieEnum.TARGETING);
            consents.push(CookieCategorieEnum.PERFORMANCE);
            break;
        case VideoProviderEnum.VIMEO:
            consents.push(CookieCategorieEnum.FUNCTIONNAL);
            consents.push(CookieCategorieEnum.PERFORMANCE);
            break;
        case VideoProviderEnum.YOUTUBE:
            consents.push(CookieCategorieEnum.TARGETING);
            consents.push(CookieCategorieEnum.FUNCTIONNAL);
            break;
    }

    return (
        <div className={styles.videoContainer} ref={videoContainer} style={{ height: videoHeight }}>
            <ConsentAware category={consents} aspectRatio="16/9">
                {videoContainer.current?.offsetWidth &&
                    (provider === VideoProviderEnum.FACEBOOK ? (
                        <>
                            <div id="fb-root"></div>
                            <Script async defer src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2" strategy="lazyOnload" />

                            <div className="fb-video" data-href={videoUrl[provider]} data-width={videoContainer.current.offsetWidth} data-show-text="false">
                                <div className="fb-xfbml-parse-ignore">
                                    <blockquote cite={videoUrl[provider]}></blockquote>
                                </div>
                            </div>
                        </>
                    ) : (
                        <iframe
                            width="100%"
                            height="100%"
                            src={videoUrl[provider]}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
                            frameBorder={0}
                            allowFullScreen={true}
                            title={provider}
                        ></iframe>
                    ))}
            </ConsentAware>
        </div>
    );
};
